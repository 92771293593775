import {fireSelects} from "../components/select";
import {closeModal} from "../components/modal";

export function submitTicket() {

    let siteUrl = window.location.origin;
    let submitTicketBtn = document.getElementById("submitTicketButton");

    if (submitTicketBtn) {
        submitTicketBtn.onclick = function (event) {
            event.preventDefault();
            let ajaxUrl = siteUrl + '/ajaxFunctions/submitTicket';
            let modalBody = document.querySelector('.modal-body');
            let form = document.getElementById('submitTicket');

            let ticketTitle = form.querySelector('#title').value;
            let requestBody = form.querySelector('#requestBody').value;
            let chooseTicketCasino = form.querySelector('#chooseTicketCasino').value;

            let casinoOption = form.querySelector("#ticketCasinoOptions").querySelector('.selected');
            let formData = new FormData(form);
            formData.append('casinoID', casinoOption ? casinoOption.dataset.value : '');

            if ((ticketTitle == null || ticketTitle === "") || (chooseTicketCasino == null || chooseTicketCasino === "") || (requestBody == null || requestBody === "")) {
                let errorHandler = form.querySelector(".form-errors");
                errorHandler.innerText = 'Please fill in the required fields';
                errorHandler.classList.remove('d-none');
                errorHandler.classList.add('d-block');
                return false;
            }

            modalBody.innerHTML = '<div class="loader">\n' +
                '<div></div>\n' +
                '<div></div>\n' +
                '</div>';

            fetch(ajaxUrl, {
                method: "POST",
                body: formData,
            })

                .then(response => response.text())
                .then(html => {
                    modalBody.innerHTML = html;
                    if (html.includes("successfully")) {
                        setTimeout(function () {
                            closeModal();
                            document.querySelector(".loadAuthAjaxModal").classList.remove('loaded');
                        }, 2500);
                    } else {
                        fireSelects();
                        submitTicket();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}