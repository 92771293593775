import jwt_decode from "jwt-decode";
import { showLoginModal, showModalWithoutData } from "../components/modal";
import { fireSelects } from "../components/select";
import { submitTicket } from "../zendesk/submitTicket";
import { dropdownElement } from "../components/dropdown-toggle";
import {isIOS} from "../components/deviceCheck";
import {zendeskLogin} from "../components/zendeskLogin";

let token = localStorage.getItem('token') ?? '';
const siteUrl = window.location.origin;

if (token) {
    const decodedToken = jwt_decode(token);
    if (decodedToken && Date.now() >= decodedToken.exp * 1000) {
        localStorage.removeItem('token');
        token = '';
    }
}

let profileForm = document.getElementById('profileForm');
let customChatViewer = document.getElementById('customChatViewer'); //for streamers

function loadGoogleSSOJsScript() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    document.body.appendChild(script);
}

window.addEventListener("load", (event) => {
    userActions()
        .then((userData) => {
            if (customChatViewer) {
                if (userData.isStreamer !== true) {
                    window.location = window.location.origin;
                }
            } else {
                if (userData.modalLogin){
                    const ajaxModal = document.getElementById("ajaxLoginModal");
                    ajaxModal.querySelector(".modal-body").innerHTML = userData.modalLogin;
                }
                profileForm ? myProfile(userData) : '';
                ticketForm(userData);
                
                document.addEventListener('click', async (event) => {
                    const element = event.target.closest('.dropdown-toggle');
                    if(!element) return;
                    dropdownElement(element);
                });

                let zendeskLoginBtn = document.getElementById('zendeskLogin');
                if (zendeskLoginBtn) {
                    zendeskLoginBtn.addEventListener("click", (event) => {
                        event.preventDefault();
                        zendeskLogin(userData);
                    });
                }
            }
        })
        .catch((err) => {
            console.log("rejected", err.message);
        })
});

export async function userActions() {
    let displayLoginRegisterButtons = document.querySelector('.users-buttons');
    const response = await fetch(siteUrl + '/ajaxFunctions/authorizeUser', {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Cache-Control": "no-cache",
            "Authorization": `Bearer ${token}`
        },
    });

    if (response.status !== 200) {
        throw new Error("cannot fetch data");
    }

    let responseData = await response.json();
    displayLoginRegisterButtons.innerHTML = responseData.user.userMenuButtons;

    if ('status' in responseData.user) {
        localStorage.removeItem('token');
        loadGoogleSSOJsScript();
        return { "modalLogin": responseData.user.modalLogin ?? '' }
    }

    return {
        "username": responseData.user.username,
        "email": responseData.user.email,
        "isStreamer": responseData.user.isStreamer
    }
}

function ticketForm(userData) {
    let modalButton = document.querySelector(".loadAuthAjaxModal");
    if (modalButton) {
        if ('modalLogin' in userData) {
            modalButton.onclick = function() {
                const currentUrl = window.location.href;
                localStorage.setItem('signUpGoBackUrl', currentUrl);
                localStorage.setItem('signUpGoBackExpiration', Date.now() + 30 * 60 * 1000);  //set 30 minutes expiration
                showLoginModal();
            }
        } else {
            modalButton.onclick = function() {
                if (modalButton.classList.contains('loaded')) {
                    showModalWithoutData();
                } else {
                    loadAuthModal(userData, modalButton);
                }
            }
        }
    }

}


function myProfile(userData) {
    let profileContent = document.querySelector('.tabs-wrapper');
    fetch(siteUrl + '/ajaxFunctions/myAccountData', {
        method: "POST",
        headers: {
            "Content-Type": "text/html",
            "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
            "email": userData.email ?? null,
            "username": userData.username ?? null,
            "update": false
        })
    })
        .then(response => response.text())
        .then(html => {
            profileContent.innerHTML = html;
        })
        .catch(function(error) {
            console.log(error);
        });
    return true;
}

function loadAuthModal(userData, button) {
    showModalWithoutData();
    const ajaxModal = document.getElementById("ajaxModal");
    ajaxModal.querySelector(".modal-body").innerHTML = '<div class="loader">\n' +
        '<div></div>\n' +
        '<div></div>\n' +
        '</div>';

    fetch(siteUrl + '/ajaxFunctions/getTicketForm', {
        method: "POST",
        headers: {
            "Content-Type": "text/html",
            "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
            "email": userData.email ?? null,
            "username": userData.username ?? null,
        })
    })
        .then(response => response.text())
        .then(html => {
            button.classList.add('loaded');
            ajaxModal.querySelector(".modal-body").innerHTML = html;
            fireSelects();
            submitTicket();
        })
        .catch(function(error) {
            console.log(error);
        });
    return true;
}
