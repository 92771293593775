export function zendeskLogin(userData) {
    const siteUrl = window.location.origin;
    fetch(siteUrl + '/ajaxFunctions/loginZendesk', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
            "email": userData.email ?? null,
            "username": userData.username ?? null,
        })
    })
        .then(response => response.json())
        .then(data => {
            window.open(data.url, '_blank').focus();
        })
        .catch(function(error) {
            console.log(error);
        });
    return true;
}